* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {height:100%;}

body {
    color: #FFF;
    background: #ebf2f5;
}

body, input, button, textarea {
    font: 600 18px Nunito, sans-serif;
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn-green {
    color: #fff!important;
    background-color: #0dac31!important;
    border-color: #0b7824!important;
    width: auto;
    height: 40px;
    border-radius: 5px!important;
    padding-top: 8px;
    margin: 10px 0 0;
    text-transform: uppercase;
    font-weight: 600!important;
    font-size: 14px!important;
}

#loading .MuiCircularProgress-root {
    color: #4c00ff;
    width: 5rem!important;
    height: 5rem!important;
  }

  #loading .MuiCircularProgress-root .MuiCircularProgress-circleIndeterminate {
    stroke-dasharray: 110px, 200px;
  }

  .MuiTypography-body1 {
    font-weight: 700!important;
    text-align: center;
  }

